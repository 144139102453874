import { defineStore } from 'pinia'
import axios from 'axios'

const useNavigationStore = defineStore('navigationStore', {
  state: () => ({
    brands: [],
    currentResort: null,
    resorts: [],
    mobileMenuOpen: false,
    breakpoint: 'desktop',
  }),

  actions: {
    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen
    },
    getBrands() {
      if (this.brands.length === 0) {
        return new Promise((resolve) => {
          axios
            .get('https://super-playa-api.lbm.co/api/brands')
            .then((response) => {
              this.brands = response.data.data
              resolve(response)
            })
            .catch((error) => {
              // @todo
              // window.deviseSettings.$bus.$emit('showError', error);
            })
        }).catch((error) => {
          // @todo
          // window.deviseSettings.$bus.$emit('showError', error);
        })
      }
    },
    getResorts() {
      if (this.resorts.length === 0) {
        return new Promise((resolve) => {
          axios
            .get('https://super-playa-api.lbm.co/api/resorts')
            .then((response) => {
              this.resorts = response.data.data
              resolve(response)
            })
            .catch((error) => {
              // @todo
              // window.deviseSettings.$bus.$emit('showError', error);
            })
        }).catch((error) => {
          // @todo
          // window.deviseSettings.$bus.$emit('showError', error);
        })
      }
    },
    getCurrentResorts() {
      if (!this.currentResort) {
        return new Promise((resolve) => {
          axios
            .get('https://super-playa-api.lbm.co/api/resorts/menu')
            .then((response) => {
              this.currentResort = response.data.data
              resolve(response)
            })
            .catch((error) => {
              // @todo
              // window.deviseSettings.$bus.$emit('showError', error);
            })
        }).catch((error) => {
          // @todo
          // window.deviseSettings.$bus.$emit('showError', error);
        })
      }
    },
  },
})

export { useNavigationStore }
